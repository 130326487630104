// 公用常量
const constant = {
  // 接口地址
  API_DOMAIN: {
    // 接口地址
    1: {
      TEST: "dev",
      // TEST: "http://192.168.1.10:8942", // 测试接口地址
      // TEST: "http://47.109.62.235:8080",
      // TEST: "https://www.cq-ncpapp.xzhlwl.cn/userinfo",
      PRODUCT: "https://www.cq-ncpapp.xzhlwl.cn/userinfo", // 线上接口地址
      // PRODUCT: "http://47.109.62.235:8080", // 线上接口地址
    },
    2: {
      TEST: " http://rjt037.dx315.cn", // 测试接口地址
      PRODUCT: " http://rjt037.dx315.cn", // 线上接口地址
    },
  },
};

export default constant;
