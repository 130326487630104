import axios from "axios";
import { apiDomains } from "@/config/index.js";
import vueapp from "@/main.js";
import store from "@/store";
import qs from "qs";
import manager from "@/store/manager.js";
const http = function (options) {
  // 获取token
  let token = store.state.token;
  let showErrToast = options.showErrToast === false ? false : true; // 是否显示错误提示
  let headers = options.headers ? options.headers : {}; // header
  if (token) {
    headers.Authorization = token;
    headers.token = token;
  }
  if (options.method === "get") {
    options.params = options.data;
  } else {
    if (options.query) {
      options.params = options.data;
    } else if (options.formData === true) {
      //普通表单提交情况
      let postData = qs.stringify(options.data);
      options.data = postData;
      headers["Content-Type"] = "application/json;charset=utf-8";
    }
    //  else if (options.multipart === true) {
    //   // 文件提交情况
    //   if (options.data.constructor != FormData) {
    //     let formData = new FormData();
    //     for (let i in options.data) {
    //       formData.append(i, options.data[i]);
    //     }
    //     options.data = formData;
    //   }
    //   headers["Content-Type"] = "application/json;charset=utf-8";
    // }
  }
  options.headers = headers;
  axios.defaults.baseURL = apiDomains[options.apiDomainIndex];
  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        const { code } = res.data;
        if (code == 200 || code == 0) {
          resolve(res.data);
        } else {
          // vueapp.$dialog.alert({
          //   title: "提示",
          //   message: data.message,
          //   confirmButtonColor: "#3b87f6",
          // });
          reject(res.data);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          // 取消了请求后的操作
        } else {
          if (err.response) {
            const { response } = err;
            if (response.status === 401) {
              vueapp.$global.localStorage.userInfo.remove();
            }
            if (response.status == 404) {
              vueapp.$toast.fail("请检查您的网络");
              return;
            }
            if (response.data.code != 200) {
              vueapp.$toast.fail(response.data.message);
              return;
            }
            if (showErrToast) {
              vueapp.$toast.fail("请检查您的网络");
            }
          } else {
            vueapp.$toast.fail("请检查您的网络");
          }
          reject({
            net_error: true,
            response: err.response,
          });
        }
      });
  });
};

export default http;
