import http from "../http.js";

const Api = {
  /**电子合格证 */
  cateId: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtProductCertificate/queryById",
      data: params,
    });
  },
  dict: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/common/getDict",
      data: params,
    });
  },
  subject: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtSubjectInfo/queryById",
      data: params,
    });
  },
  showPlan: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtSubjectShowPlan/getShowPlan",
      data: params,
    });
  },
  //追溯信息
  zhusxinx: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      // url: "/rjtProductCertificate/queryByPage",
      url: "/rjtCertificatePrint/queryByPage",
      data: params,
    });
  },
  zhusxq: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtProductionRecord/queryById",
      data: params,
    });
  },
  //生产信息
  shengchanxinx: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      // url: "/rjtCertificatePrint/queryByPage",
      url: "/rjtProductionRecord/queryByPage",
      data: params,
    });
  },
  //监管信息
  jianguanxinx: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtCertificatePrint/queryByPage",
      data: params,
    });
  },
  //检测信息
  jianche: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: "/rjtCheck/queryByPage",
      data: params,
    });
  },
  //编码
  bianm: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "get",
      url: `/QRCode/getByLabel/${params.labId}`,
    });
  },
  tickets: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "get",
      url: `/QRCode/verifyCode?code=${params.labId}${params.securityCode}`,
      // data: params,
    });
  },
  //编码查询
  chaxun: (params = {}) => {
    return http({
      apiDomainIndex: 2,
      method: "get",
      url: `/openapi/antifake/verify`,
      headers: {
        ticket: params,
      },
      // data: params,
    });
  },
  //获取承诺依据对应高亮信息
  taskAnd: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "get",
      url: `/QRCode/whetherTaskAndLog?productBatchId=${params.productBatchId}`,
    });
  },
  //记录扫描合格证二维码次数
  jilcs: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: `/rjtCertificateQuery/add`,
      data: params,
    });
  },
  //企业资质
  qiyeziz: (params = {}) => {
    return http({
      apiDomainIndex: 1,
      method: "post",
      url: `/rjtQualificateCert/queryByPage`,
      data: params,
    });
  },
};

export default Api;
