import constant from "./constant.js";
let apiEnv = "test"; // api接口环境 test-测试环境 product-线上环境
if (process.env.NODE_ENV == "production") {
  apiEnv = "product";
}
// 根据api接口环境获取接口domain
const apiDomains = (function (apiEnv) {
  let apiUrl = {};
  let env = apiEnv;
  for (let i in constant.API_DOMAIN) {
    // if (env === "dev") {
    //   apiUrl[i] = constant.API_DOMAIN[i].DEV;
    // }
    if (env === "test") {
      apiUrl[i] = constant.API_DOMAIN[i].TEST;
    }
    if (env === "product") {
      apiUrl[i] = constant.API_DOMAIN[i].PRODUCT;
    }
  }
  return apiUrl;
})(apiEnv);

export { apiDomains };
